import { FC, useContext } from 'react';
import { TrimStyle } from '@/types';
import { CollapsibleToggleContext } from '@/components/atoms/legacy/CollapsibleSection/CollapsibleSection';
import { getRange } from '@/components/organisms/ChooseTrimForm/helpers';
import { priceFormatter } from '@/lib/priceFormatter';
import { PRICE_COMING_SOON } from '@/lib/constants';

const TrimCard: FC<{
  trim: TrimStyle;
  onClick: (trim: TrimStyle) => void;
  selected: boolean;
}> = ({ trim, onClick, selected }) => {
  const toggleParent = useContext(CollapsibleToggleContext);
  const range = getRange(trim);
  const hasRPM = trim.horsepower.includes('@');
  const horsePower = hasRPM ? trim.horsepower.split('@')[0] : trim.horsepower;
  const rpm = hasRPM ? trim.horsepower.split('@')[1] : undefined;
  const HPDisplay = rpm ? `${horsePower} hp @ ${rpm} rpm` : `${horsePower} hp`;

  return (
    <div
      className={`inline-flex w-full cursor-pointer flex-col items-start justify-start gap-3 rounded border p-5 ${
        selected
          ? 'border-2 border-black bg-background-light'
          : 'border-gray-300 bg-background-white'
      }`}
      onClick={() => {
        onClick(trim);
        toggleParent.toggle();
      }}
    >
      <div className="inline-flex items-start justify-start gap-5 self-stretch">
        <div className="shrink grow basis-0 text-[16px] font-medium leading-snug tracking-tight text-neutral-800">
          {trim.trim}
        </div>
        <div className="text-right text-base font-light leading-snug tracking-tight text-neutral-800">
          {priceFormatter(trim.msrp, PRICE_COMING_SOON)}
        </div>
      </div>
      <div className="self-stretch text-[14px] font-light leading-tight tracking-tight text-zinc-600">
        Est. Range {range?.value} {range?.unit} | Up to {HPDisplay}
      </div>
    </div>
  );
};

export default TrimCard;
